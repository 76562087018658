import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
   {
      path: '/',
      name: 'home',
      component: HomeView
   },
   {
      path: '/about/:slug',
      name: 'about',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/About/AboutView.vue')
   },
   {
      path: "/recipe",
      name: "recipe",
      component: () =>
         import(
            "../views/Reciept/RecipeView.vue"
         ),
   },
   {
      path: '/catalog',
      name: 'catalog',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Catalog/CatalogView.vue')
   },
   {
      path: '/article/:slug',
      name: 'article',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Articles/ArticleView.vue')
   },
   {
      path: '/news',
      name: 'news',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/News/NewsView.vue')
   },
   {
      path: '/documents',
      name: 'documents',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Documents/DocumentsView.vue')
   },
   {
      path: '/contacts',
      name: 'contacts',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Contacts/ContactsView.vue')
   },
   {
      path: '/product/:uuid',
      name: 'product',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Product/ProductView.vue')
   },
   {
      path: '/catalog/:category/:type',
      name: 'category',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Category/CategoryView.vue')
   },
   {
      path: '/search',
      name: 'search',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Search/SearchView.vue')
   },
   {
      path: '/catalog/:category',
      name: 'catalogSecond',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/Catalog/CatalogSecondView')
   },
   {
      path: '/catalog/allProducts',
      name: 'allProducts',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../views/All/AllProductsView')
   },
   {
      path: '/',
      name: 'public',
      props: true,
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "about" */ '../layouts/DefaultLayout'),
      redirect: "/",
      children: [
         {
            path: '/404',
            name: '404',
            props: true,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ '../views/Error404/Error404View'),
         },
      ],
   },
   {
      path: '/:catchAll(.*)',
      redirect: '404'
   },
]

const router = createRouter({
   history: createWebHistory(process.env.BASE_URL),
   routes,
   scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
   }
})

export default router
